/*****************************/
/** [Bootstrap 4 variables] **/
/*****************************/
//
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 1024px,
  lg: 1200px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 1200px
);