
$spacer: 1rem !default;
$td-spacers-postfix: '' !default; // Optionally add a postfix to the spacing classes (example: 'px' will generate 'mb-5px').

$spacers: ( // spacers using equivalent 'px' units.
        0+$td-spacers-postfix: 0,
        1+$td-spacers-postfix: ($spacer * (1/16)),
        2+$td-spacers-postfix: ($spacer * (2/16)),
        3+$td-spacers-postfix: ($spacer * (3/16)),
        4+$td-spacers-postfix: ($spacer * (4/16)),
        5+$td-spacers-postfix: ($spacer * (5/16)),
        10+$td-spacers-postfix: ($spacer * (10/16)),
        15+$td-spacers-postfix: ($spacer * (15/16)),
        20+$td-spacers-postfix: ($spacer * (20/16)),
        25+$td-spacers-postfix: ($spacer * (25/16)),
        30+$td-spacers-postfix: ($spacer * (30/16)),
        35+$td-spacers-postfix: ($spacer * (35/16)),
        40+$td-spacers-postfix: ($spacer * (40/16)),
        45+$td-spacers-postfix: ($spacer * (45/16)),
        50+$td-spacers-postfix: ($spacer * (50/16))
) !default;
